// Media queries
$mobile: 480px;
$tablet: 1010px;
$laptop: 1450px;

// Fontes
$font: "Inter Regular", sans-serif;
$font-bold: "Inter Bold", sans-serif;
$font-medium: "Inter Medium", sans-serif;

// Couleurs
$light-grey: #f5f5f5;
$almost-light-grey: #e0e0e0;
$color-black: #424242;
$color-very-black: #212121;
