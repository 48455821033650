.login-form {
  width: 400px;
  margin: 0 auto;
  margin-top: 10em;
  padding: 30px 35px;
  background: #eeeeee;
  border-radius: 5px;

  .form-title {
    font-family: $font-bold;
    font-size: 24px;
    color: $color-very-black;
    margin-bottom: 40px;
  }

  label {
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      font-family: $font;
      font-size: 14px;
      color: $color-very-black;
    }

    input {
      margin-top: 10px;
      outline: none;
      border: initial;
      border-bottom: 1px solid $color-very-black;
      background: transparent;
      font-family: $font;
      font-size: 14px;
    }
  }

  @media (max-width: $mobile) {
    width: 85vw;
  }
}
