@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/Inter-Regular.otf");
}

@font-face {
  font-family: "Inter Bold";
  src: url("../fonts/Inter-Bold.otf");
}

@font-face {
  font-family: "Inter Medium";
  src: url("../fonts/Inter-Medium.otf");
}
