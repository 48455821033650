.not-found {
  width: 1600px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: $laptop) {
    width: 85vw;
  }

  .not-found__title {
    font-family: $font-bold;
    font-size: 36px;
    margin-bottom: 20px;
    color: $color-very-black;
  }

  .not-found__subtitle {
    font-family: $font;
    font-size: 18px;
  }
}
