.block-home {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6em;
  padding-bottom: 60px;

  .block-home__logo-container {
    width: 100%;
    height: 260px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .block-home__block-signatures {
    width: 80vw;
    height: auto;
    padding-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .signature {
      background: $light-grey;
      padding: 20px 25px;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 5px;
      font-family: $font-bold;
      color: $color-black;
      font-size: 20px;
      transition: background 250ms linear, box-shadow 250ms linear,
        transform 250ms ease-in-out;

      &:after {
        content: "-->";
        font-family: $font-medium;
        padding-top: 10px;
      }

      &:hover {
        background: $almost-light-grey;
        box-shadow: 0px 10px 10px -1px rgba(0, 0, 0, 0.2);
        transform: translateY(-8px);
        transition: background 250ms linear, box-shadow 250ms linear,
          transform 250ms ease-in-out;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .block-access-admin {
    margin-top: 80px;

    a {
      font-family: $font-medium;
      color: $color-black;
      font-size: 16px;
      transition: color 250ms linear;

      &:hover {
        color: black;
        transition: color 250ms linear;
      }
    }
  }
}
